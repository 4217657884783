// Dependencies
import React from "react";
import classnames from "classnames";

export default function Input({ isInvalid, ...props }) {
	return (
		<div className="relative w-full">
			<input
				className={classnames(
					`	bg-gray-50 border-2
						border-gray-300
						text-gray-900
						focus:ring-blue-500
						focus:border-blue-500
						block
						border-box
						text-sm
						rounded-lg
						w-full
						p-2.5`,
					{ "border-red-600 ": isInvalid },
				)}
				{...props}
			/>
			{isInvalid ? (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth="1.5"
					stroke="currentColor"
					className="absolute w-6 h-6 stroke-red-500 top-2.5 right-[12px]"
				>
					<title>Invalid field</title>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
					/>
				</svg>
			) : (
				false
			)}
		</div>
	);
}
