// Dependencies
import { Input, Recaptcha, Button } from "@components";
import logoTrucksur from "@images/trucksur_logo_simple.svg";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl";
import { capitalize } from "lodash";
import React, { cloneElement } from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { Formik } from "formik";
import useFooter from "@hooks/useFooter";
import { GATSBY_API_ROOT } from "@lib/env";

const FOOTER_LINKS = [{ intlId: "misc_quality", href: "/politica-calidad" }];

const SOCIAL_LINKS = [
	{ id: "twitter", href: "https://twitter.com/trucksur", icon: FaTwitter },
	{
		id: "facebook",
		href: "https://www.facebook.com/Trucksur",
		icon: FaFacebook,
	},
	{
		id: "instagram",
		href: "https://www.instagram.com/trucksur_",
		icon: FaInstagram,
	},
];

const ImagesQuery = graphql`
  {
    aenor: file(relativePath: { eq: "aenor_logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 140, layout: CONSTRAINED)
      }
    }
  }
`;

export default function Footer() {
	const images = useStaticQuery(ImagesQuery);
	const { schema, onSubmit, initialValues, fetching, recaptchaRef } =
		useFooter();
	const intl = useIntl();

	return (
		<footer className="bg-gray-800 py-4 text-white">
			<div className="container mx-auto">
				<div className="grid grid-cols-1 gap-6">
					<div className="max-w-[180px]">
						<img src={logoTrucksur} alt="Trucksur" />
					</div>
					<div className="grid grid-cols-1 gap-6 md:grid-cols-2 items-start">
						<Formik
							validationSchema={schema}
							onSubmit={onSubmit}
							initialValues={initialValues}
						>
							{({ handleSubmit, handleChange, values, touched, errors }) => {
								return (
									<form onSubmit={handleSubmit} noValidate={true}>
										<Input
											type="email"
											name="email"
											value={values.email}
											onChange={handleChange}
											placeholder={intl.formatMessage({
												id: "misc_enter_email",
											})}
											isInvalid={touched.email && errors.email}
										/>
										<Recaptcha ref={recaptchaRef} />
										<Button
											type="submit"
											color="gray"
											light
											className="mt-2"
											isLoading={fetching}
										>
											{intl.formatMessage({ id: "misc_suscribe_newsletter" })}
										</Button>
									</form>
								);
							}}
						</Formik>
						<div className="grid grid-cols-1 gap-3">
							<div className="flex justify-center md:justify-end font-light">
								<FormattedMessage id="misc_follow" />
							</div>
							<div className="flex justify-center md:justify-end min-w-full">
								<div className="flex justify-between w-40">
									{SOCIAL_LINKS.map((social) => {
										const icon = cloneElement(social.icon(), {
											size: "2rem",
											className: "fill-white hover:fill-sky-300 transition-all",
										});
										return (
											<a
												key={social.id}
												href={social.href}
												target="_blank"
												rel="noreferrer"
											>
												{icon}
											</a>
										);
									})}
								</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 gap-6 items-center xs:grid-cols-2 md:grid-cols-[minmax(0,_750px)_minmax(200px,_1fr)] ">
						<ul className="flex flex-col md:flex-row justify-between flex-wrap">
							{FOOTER_LINKS.map((link) => {
								return (
									<li
										className="px-3 text-gray-400 text-center py-1"
										key={link.intlId}
									>
										<Link
											to={link.href}
											className="hover:text-white hover:no-underline transition-all"
										>
											{capitalize(intl.formatMessage({ id: link.intlId }))}
										</Link>
									</li>
								);
							})}
						</ul>
						<div className="flex justify-center md:justify-end">
							<a
								href={`${GATSBY_API_ROOT}/static/other/25.11.11.ISO-9001_TRUCKSUR.pdf`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<GatsbyImage
									image={images?.aenor?.childImageSharp?.gatsbyImageData}
									alt="Aenor"
								/>
							</a>
						</div>
					</div>
					<span className="block text-sm font-light text-center text-gray-400">
						<FormattedMessage id="misc_footer" />
					</span>
				</div>
			</div>
		</footer>
	);
}
