//Import Styles
import React from "react";
import { Script } from "gatsby";
import { Toaster } from "react-hot-toast";
import { FavoritesProvider } from "@context/FavoritesContext";
import { MobileMenuProvider } from "@context/MobileMenuContext";
import { GATSBY_ZENDESK_KEY } from "@lib/env";
import { ZendeskHelp, StaticButtonPhone } from "@components";
import "@styles/global.css";

export function shouldUpdateScroll({ routerProps: { location } }) {
	return !location.search;
}

export function wrapRootElement({ element }) {
	return (
		<MobileMenuProvider>
			<FavoritesProvider>{element}</FavoritesProvider>
		</MobileMenuProvider>
	);
}

export function wrapPageElement({ element, ...rest }) {
	const language = rest.props.pageContext.intl.language;
	return (
		<>
			<Script
				type="text/javascript"
				src="https://cdn.lawwwing.com/widgets/current/08490754-44f1-42d8-86f0-1a3cd8285451/cookie-widget.min.js"
				data-lwid="08490754-44f1-42d8-86f0-1a3cd8285451"
			/>
			<Toaster
				toastOptions={{
					duration: 2000,
				}}
			/>
			<ZendeskHelp
				language={language}
				zendeskKey={GATSBY_ZENDESK_KEY}
				settings={{
					webWidget: {
						zIndez: -1,
					},
				}}
			/>
			<StaticButtonPhone phone="+34900100200" />
			{element}
		</>
	);
}
