// Dependencies
import React from "react";
import { menu as menuItems } from "@constants";
import { Link, useIntl, IntlContextConsumer } from "gatsby-plugin-intl";
import classNames from "classnames";

export default function Menu({ className }) {
	const intl = useIntl();
	return (
		<nav
			className={classNames(
				"w-full border-t-0 border-white md:border-t",
				className,
			)}
		>
			<IntlContextConsumer>
				{({ originalPath }) => {
					return (
						<ul className="flex flex-col md:flex-row justify-between">
							{menuItems.map((item) => {
								const isActive = item.href === originalPath;
								return (
									<li
										className={classNames(
											"py-[0.5rem] text-center md:py-[15px] md:px-[10px]",
											isActive ? "md:bg-truckGold" : "md:bg-transparent",
										)}
										key={item.label_id}
									>
										<Link
											to={item.href}
											className={classNames(
												"flex items-center h-full text-center text-[1.3rem] md:text-[0.9rem] md:text-white hover:text-white hover:no-underline",
												isActive ? "text-truckGold" : "text-white",
												isActive ? "md:bg-truckGold" : "md:bg-transparent",
											)}
										>
											{intl.formatMessage({ id: item.label_id }).toUpperCase()}
										</Link>
									</li>
								);
							})}
						</ul>
					);
				}}
			</IntlContextConsumer>
		</nav>
	);
}
