// Dependencies
import React, { useContext, useEffect } from "react";
import { Header, Footer, FavoritesMenu } from "@components";
import classNames from "classnames";
import FavoritesContext from "@context/FavoritesContext";
import MobileMenuContext from "@context/MobileMenuContext";

export default function Layout({ children, location }) {
	const { setIsOpen: setOpenFavorites } = useContext(FavoritesContext);
	const { isOpen: isOpenMobileMenu, setIsOpen: setOpenMobileMenu } =
		useContext(MobileMenuContext);

	useEffect(() => {
		setOpenFavorites(false);
		setOpenMobileMenu(false);
	}, [location, setOpenFavorites, setOpenMobileMenu]);

	return (
		<div
			className={classNames("min-h-screen flex flex-col", {
				"h-screen overflow-hidden": isOpenMobileMenu,
			})}
		>
			<FavoritesMenu />
			<Header />
			<div className="flex-1 mb-20 flex flex-col">{children}</div>
			<Footer />
		</div>
	);
}
