// Dependencies
import { useEffect, useCallback } from "react";

const canUseDOM = () => {
	if (
		typeof window === "undefined" ||
		!window.document ||
		!window.document.createElement
	) {
		return false;
	}
	return true;
};

export default function ZendeskHelp({ language, zendeskKey, settings }) {
	const setLanguage = useCallback(() => {
		if (canUseDOM && window.zE) {
			window.zE("webWidget", "setLocale", language);
		}
	}, [language]);

	const insertScript = useCallback(() => {
		const script = document.createElement("script");
		script.async = true;
		script.id = "ze-snippet";
		script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
		script.addEventListener("load", () => setLanguage(language));
		document.body.appendChild(script);
	}, [zendeskKey, setLanguage, language]);

	const removeOldScript = () => {
		const oldScript = document.getElementById("ze-snippet");
		if (oldScript) {
			document.body.removeChild(oldScript);
		}
	};

	useEffect(() => {
		if (canUseDOM && !window.zE) {
			removeOldScript();
			insertScript();
			window.zESettings = { ...settings };
		}
	}, [insertScript, settings]);

	useEffect(() => {
		setLanguage(language);
	}, [setLanguage, language]);

	return null;
}
