const where = [
	{
		place: "Alicante",
		adress_1: "Pol. Ind Las Atalayas",
		adress_2: "Calle del Franco 3",
		province: "03114 Alicante",
		phone_1: "+34965103540",
		email: "info@trucksur.com",
	},
	{
		place: "Almería",
		adress_1: "Parque Comercial Los Naranjos",
		adress_2: "Ctra. Nac. 340 Km. 449",
		province: "04230 Huércal de Almería, Almería",
		phone_1: "+34950950314",
		email: "info@trucksur.com",
	},
	{
		place: "Barcelona Barberá del Vallés",
		adress_1: "Carrer Gorchs Lladó, 112",
		province: "08210 Barberà del Vallés, Barcelona",
		phone_1: "+34902504450",
		email: "info@trucksur.com",
	},
	{
		place: "Barcelona Mercabarna",
		adress_1: "Carrer Transversal,2, 50",
		province: "08040 Barcelona",
		phone_1: "+34931790962",
		email: "info@trucksur.com",
	},
	{
		place: "Bilbao",
		adress_1: "Ugarte Kalea, 6",
		province: "48510 Trapaga, Bizkaia",
		phone_1: "+34944356119",
		email: "info@trucksur.com",
	},
	{
		place: "Cádiz",
		adress_1: "Avda. del Transporte, 57-67",
		adress_2: "Ctra. Madrid - Cádiz, Km. 634",
		province: "11591 Jerez de la Frontera, Cádiz",
		phone_1: "+34956182858",
		email: "info@trucksur.com",
	},
	{
		place: "Cantabria",
		adress_1: "Barrio La Calva, 18-A",
		province: "39600 Revilla de Camargo, Cantabria",
		phone_1: "+34942581312",
		email: "info@trucksur.com",
	},
	{
		place: "Córdoba",
		adress_1: "Ingeniero Juan de la Cierva, s/n",
		adress_2: "Pol. Ind. Amargacena",
		province: "14013 Córdoba",
		phone_1: "+34957429684",
		email: "info@trucksur.com",
	},
	{
		place: "Girona",
		adress_1: "Carrer de Sarrià de Ter, 18",
		province: "17005 Girona, Girona",
		phone_1: "+34872987073",
		email: "info@trucksur.com",
	},
	{
		place: "Granada",
		adress_1: "Autovía A-92, Salida 225",
		province: "18330 Chauchina, Granada",
		phone_1: "+34958446688",
		email: "info@trucksur.com",
	},
	{
		place: "Huelva",
		adress_1: "Parque Huelva Empresarial",
		adress_2: "Avda. La Gamba nº7 parcela 1.2",
		province: "21007 Huelva",
		phone_1: "+34959232427",
		email: "info@trucksur.com",
	},
	{
		place: "Madrid C.T.M.",
		adress_1: "Ctra. Villaverde Vallecas, Km. 3.5",
		adress_2: "CTM, Sector 4, Nave 2",
		province: "28053 Madrid",
		phone_1: "+34915079836",
		email: "info@trucksur.com",
	},
	{
		place: "Madrid San Fdo. Henares",
		adress_1: "Avda. de Castilla, 29",
		province: "28830 San Fdo Henares, Madrid",
		email: "info@trucksur.com",
	},
	{
		place: "Málaga",
		adress_1: "Ctra. de Azucarera a Intelhorce, 74",
		province: "29004 Málaga",
		phone_1: "+34952176631",
		email: "info@trucksur.com",
	},
	{
		place: "Mérida",
		adress_1: "C/ Zaragoza, Parcela 38",
		adress_2: "Pol. Ind. El Prado",
		province: "06800 Mérida, Badajoz",
		phone_1: "+34924389039",
		email: "info@trucksur.com",
	},
	{
		place: "Murcia",
		adress_1: "C/ Buenos Aires S/N",
		adress_2: "Pol. Ind. La Serreta",
		province: "30500 Molina de Segura, Murcia",
		phone_1: "+34968687513",
		email: "info@trucksur.com",
	},
	{
		place: "Sevilla",
		adress_1: "Ctra. de Málaga, Km. 4,3, 112",
		adress_2: "Políg. Ind. La Red, Parc. 52",
		province: "41500 Alcalá de Guadaíra, Sevilla",
		phone_1: "+34955634340",
		email: "info@trucksur.com",
	},
	{
		place: "Valencia",
		adress_1: "Avenida Comarques Del País Valenciá, Nº 51",
		province: "46930 Quart de Poblet, Valencia",
		phone_1: "+34961525783",
		email: "info@trucksur.com",
	},
	{
		place: "Zaragoza",
		adress_1: "C/ Escatrón, 20",
		province: "50014 Zaragoza",
		phone_1: "+34976476776",
		phone_2: "+34690655536",
		email: "info@trucksur.com",
	},
];

export default where;
