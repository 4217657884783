// Dependencies
import React from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import info from "../constants/info";

export default function InfoList() {
	const intl = useIntl();
	return (
		<div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 my-4">
			{info.map((item, index, self) => {
				const isLastElement = self.length === index + 1;
				return (
					<div key={item.key}>
						<div className="relative w-full bg-transparent">
							<div className="flex flex-col p-3">
								<img
									className="max-h-[40px] h-full"
									src={item.icon.default}
									alt={intl.formatMessage({ id: item.key })}
								/>
								<div className="mt-[5px] text-gray-500 text-center">
									<FormattedMessage id={item.key} />
								</div>
							</div>
							{!isLastElement ? (
								<div className="w-[0px] absolute top-0 right-0 h-full lg:w-1 bg-gray-300 " />
							) : null}
						</div>
					</div>
				);
			})}
		</div>
	);
}
