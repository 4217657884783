module.exports = [
	{
		key: "cat_tractor_head",
		type: "Cabezas tractoras",
		seo: "tractoras",
		seo_title_init: "Cabezas tractoras de ocasión",
	},
	{
		key: "cat_rigid",
		type: "Vehículos Rígidos",
		seo: "rigidos",
		seo_title_init: "Vehículos rígidos de ocasión",
	},
	{
		key: "cat_construction",
		type: "Construccion",
		seo: "construccion",
		seo_title_init: "Vehículos de construcción de ocasión",
	},
	{
		key: "cat_refrigerated",
		type: "Vehículos frigoríficos",
		seo: "frigorificos",
		seo_title_init: "Vehículos frigoríficos de ocasión",
	},
	{
		key: "cat_semitrailer",
		type: "Semirremolques y Carrocerías",
		seo: "semirremolques",
		seo_title_init: "Semirremolques y carrocerias de ocasión",
	},
	{
		key: "cat_light",
		type: "Vehículos ligeros",
		seo: "ligeros",
		seo_title_init: "Vehículos ligeros de ocasión",
	},
	{
		key: "cat_bus",
		type: "Autobuses",
		seo: "bus",
		seo_title_init: "Autobuses de ocasión",
	},
	{
		key: "cat_cars",
		type: "Automóviles",
		seo: "turismos",
		seo_title_init: "Turismos de ocasión",
		isExternal: true,
		href: "https://grupoavisa.com/vehiculos-de-ocasion",
	},
];
