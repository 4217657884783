// Dependencies
import React from "react";
import { useIntl, Link } from "gatsby-plugin-intl";

export default function LinkIndexSegment({ href, idLabel }) {
	const intl = useIntl();
	return (
		<Link to={href} className="hover:no-underline hover:font-medium">
			<div className="text-3xl text-truckGold hover:text-truckGold">
				{intl.formatMessage({ id: idLabel })}
			</div>
			<div className="text-md text-gray-600 hover:text-gray-600">
				{intl.formatMessage({ id: "misc_see_more" })} ...
			</div>
		</Link>
	);
}
