// Dependencies
import React, { createContext, useState } from "react";

const MobileMenuContext = createContext({
	isOpen: false,
});

export function MobileMenuProvider({ children }) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<MobileMenuContext.Provider
			value={{
				isOpen,
				setIsOpen,
			}}
		>
			{children}
		</MobileMenuContext.Provider>
	);
}

export default MobileMenuContext;
