// Dependencies
import {
	LogoRentDelegations,
	LogoRentAttention,
	LogoRentAvailability,
	LogoRentExperience,
} from "@components";

const rent = [
	{
		key: "experience",
		logo: LogoRentExperience,
		text_id: "rent_item_experience",
	},
	{
		key: "availability",
		logo: LogoRentAvailability,
		text_id: "rent_item_availability",
	},
	{
		key: "delegations",
		logo: LogoRentDelegations,
		text_id: "rent_item_delegations",
	},
	{
		key: "attention",
		logo: LogoRentAttention,
		text_id: "rent_item_attention",
	},
];

export default rent;
