// Dependencies
import React from "react";
import { Button } from "@components";
import classNames from "classnames";

export default function EmptyVehicles({ className, resetFilters, isFiltered }) {
	return (
		<div
			className={classNames(
				"w-full h-fit flex flex-col gap-3 items-center justify-center p-6 border border-gray-500 rounded-xl shadow-md",
				className,
			)}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				strokeWidth="1"
				stroke="currentColor"
				className="w-20 h-20 stroke-gray-400"
			>
				<title>No hay vahículos guardados</title>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
				/>
			</svg>
			<div className="text-xl text-center text-gray-500">
				No hay vehículos para mostrar
			</div>
			{isFiltered && resetFilters ? (
				<Button className="bg-red-700 hover:bg-red-800" onClick={resetFilters}>
					Pulse aquí para resetear el filtro
				</Button>
			) : null}
		</div>
	);
}
