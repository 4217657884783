// Dependencies
import React, { useContext } from "react";
import { FavoritesMenuItem } from "@components";
import FavoritesContext from "@context/FavoritesContext";

export default function FavoritesMenuList() {
	const { displayedFavorites } = useContext(FavoritesContext);

	return displayedFavorites?.length ? (
		<ul className="flex flex-col gap-3">
			{displayedFavorites.map((fav) => (
				<FavoritesMenuItem data={fav} key={fav.id} />
			))}
		</ul>
	) : (
		<div className="flex flex-col gap-2 items-center justify-center w-full h-full p-3">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				strokeWidth="1"
				stroke="currentColor"
				className="w-24 h-24 stroke-trucksur-600"
			>
				<title>No hay vahículos guardados</title>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
				/>
			</svg>
			<div className="text-2xl text-center text-trucksur-600">
				No hay vehículos guardados
			</div>
		</div>
	);
}
