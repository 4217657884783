// Dependencies
import { FormattedMessage } from "gatsby-plugin-intl";
import React, { useEffect, useMemo, useState } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

export default function RangeSliderFilter({
	id,
	onApply,
	state,
	label,
	formatter,
	data,
}) {
	const { min, max, step } = data;

	const stateArray = useMemo(() => {
		return state[id] ? state[id].split(",").map((v) => Number(v)) : null;
	}, [state, id]);

	const [value, setValue] = useState(stateArray || [min, max]);

	useEffect(() => {
		setValue(stateArray || [min, max]);
	}, [stateArray, min, max]);

	function handleChange(value) {
		setValue(value);
	}

	function handleApplyFilter() {
		onApply(id, value);
	}

	const isNoRender =
		min === max ||
		min === undefined ||
		max === undefined ||
		min === Infinity ||
		max === -Infinity ||
		step === 0;

	return isNoRender ? null : (
		<div className="py-2 flex flex-col gap-1 border-b border-gray-200">
			<div className="font-medium text-md">{label}</div>
			<div className="flex text-sm justify-between">
				<div className="flex gap-1 items-center">
					<div className="font-medium">Desde:</div>
					<div className="bg-trucksur-600/70 text-white px-2 rounded-full">
						{formatter ? formatter(value[0]) : value[0]}
					</div>
				</div>
				<div className="flex gap-1 items-center">
					<div className="font-medium">Hasta:</div>
					<div className="bg-trucksur-600/70 text-white px-2 rounded-full">
						{formatter ? formatter(value[1]) : value[1]}
					</div>
				</div>
			</div>

			<div className="my-4 filter-vehicles-slider">
				<RangeSlider
					value={value}
					defaultValue={[min, max]}
					onInput={handleChange}
					className="range-slider-dual-filter"
					{...data}
				/>
			</div>
			<div className="flex justify-end">
				<button
					className="text-white bg-trucksur-600 hover:bg-trucksur-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-2 py-1 text-center"
					type="button"
					onClick={handleApplyFilter}
				>
					<FormattedMessage id="misc_apply_filter" />
				</button>
			</div>
		</div>
	);
}
