// Dependencies
import React from "react";
import { FaPhoneAlt, FaAt } from "react-icons/fa";
import { prettyPhoneNumber } from "@lib/utils";

export default function WhereListItem({ data }) {
	const upperText = data.nombre_ubicacion.toUpperCase();
	const firstLetter = upperText.substring(0, 1);
	const restText = upperText.substring(1, data.nombre_ubicacion.length);

	return (
		<div className="h-full border shadow-md border-gray-300 rounded-xl py-3 px-3 font-normal text-truckBlue">
			<div className="h-20 text-gray-600">
				<div className="flex items-center font-bold text-[1.4rem]">
					<div className="flex flex-shrink-0 justify-center items-center border-4 border-gray-600 w-[40px] h-[40px] rounded-[40px]">
						<div className="text-[1.6rem]">{firstLetter}</div>
					</div>
					<div className="ml-[5px]">{restText}</div>
				</div>
			</div>

			<div>
				<div>{data.direccion_1}</div>
				<div>{data.direccion_2}</div>
				<div>{`${data.poblacion || ""}${
					data.poblacion ? ` (${data.poblacion})` : ""
				}`}</div>
				{data.telefono_1 ? (
					<div className="flex items-center">
						<FaPhoneAlt className="mr-2 fill-truckBlue" />
						<a href={`tel:${data.telefono_1}`}>
							{prettyPhoneNumber(data.telefono_1)}
						</a>
					</div>
				) : (
					false
				)}
				{data.telefono_2 ? (
					<div className="flex items-center">
						<FaPhoneAlt className="mr-2 fill-truckBlue" />
						<a href={`tel:${data.telefono_2}`}>
							{prettyPhoneNumber(data.telefono_2)}
						</a>
					</div>
				) : (
					false
				)}
				{data.email ? (
					<div className="flex items-center">
						<FaAt className="mr-2 fill-truckBlue" />
						<a href={`mailto:${data.email}`}>{data.email}</a>
					</div>
				) : (
					false
				)}
			</div>
		</div>
	);
}
