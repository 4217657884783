// Dependencies
import React, { useMemo, useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { prettyNumber } from "@lib/utils";
import { LogoAvatar } from "@components";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { scroller } from "react-scroll";
import { FiPhoneCall } from "react-icons/fi";

const VehicleBasicInfo = ({ data, className }) => {
	const stickyRef = useRef();
	const intl = useIntl();

	const { titulo, precio, seo_name } = data.vehicle;

	const dataComercial = {
		...data.vehicle.comercial,
		avatar: data.vehicle.fields.comercialImage,
	};

	const whatsappLink =
		dataComercial &&
		`https://api.whatsapp.com/send?phone=34${dataComercial.telefono}&text=Estoy%20interesado%20en%20el%20siguiente%20veh%C3%ADculo%20https://trucksur.com/${intl.locale}/vehiculo/${seo_name}`;

	const comercialImageData =
		dataComercial?.avatar?.localFile?.childImageSharp?.gatsbyImageData;

	const _renderComercialImage = comercialImageData ? (
		<GatsbyImage
			className="rounded-full w-[70px] h-[70px] object-cover"
			image={dataComercial?.avatar?.localFile?.childImageSharp?.gatsbyImageData}
			alt="avatar"
		/>
	) : (
		<LogoAvatar className="w-[60px] h-[60px] fill-truckBlue" />
	);

	function scrollToForm() {
		const measuresElement = stickyRef?.current?.getBoundingClientRect();
		const heightElement = measuresElement?.height;
		if (heightElement) {
			scroller.scrollTo("vehicleRequestInfo", {
				duration: 1000,
				smooth: true,
				offset: heightElement * -1 - 16,
			});
		}
	}

	const refMeasures = useMemo(() => {
		const measures = stickyRef?.current?.getBoundingClientRect();
		return { height: measures?.height || 0, width: measures?.width || 0 };
	}, []);

	return (
		<div
			ref={stickyRef}
			className={classNames(
				"border-[2px] border-truckBlue rounded-md shadow-md py-2.5 px-2 md:!py-3 md:!px-4 z-[50] bg-gray-50 text-truckBlue",
				`h-${refMeasures.height} w-${refMeasures.width} sticky top-[10px]`,
				className,
			)}
		>
			<div className="flex flex-col md:flex-row gap-1 items-start md:items-center justify-between">
				<div className="flex-1">
					<h1 className="text-lg lg:text-xl font-bold">{titulo}</h1>
				</div>

				<div className="flex justify-end items-center">
					<span className="text-lg lg:text-xl font-bold">
						{prettyNumber(precio, 0, "€")}
					</span>
					<span className="text-sm ml-2 font-light">
						{intl.formatMessage({ id: "misc_iva_no" })}
					</span>
				</div>
			</div>

			<hr className="my-2" />

			<div className="grid grid-cols-[1fr_auto] gap-5">
				<div className="flex flex-col gap-1">
					<span className="font-light text-sm">
						<FormattedMessage id="commercial.title" />
					</span>
					<div className="flex items-center">
						{_renderComercialImage}
						<div className="text-sm ml-2">
							<div className="font-medium text-md">{dataComercial.name}</div>
							<div>+34 {dataComercial.telefono}</div>
							<div>{dataComercial.email}</div>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-1 md:grid-cols-[auto_auto_1fr] gap-2 justify-center items-center">
					<a
						href={whatsappLink}
						className="flex justify-center items-center"
						target="_blank"
						rel="noreferrer"
					>
						<svg viewBox="0 0 11.87 11.87" className="h-8 w-8 md:h-12 md:w-12">
							<title>Whatsapp</title>
							<path
								d="M.94,8.44c-.43-.82-.65-1.74-.65-2.67C.28,2.59,2.87,0,6.05,0c1.54,0,2.99,.6,4.08,1.69,1.12,1.12,1.74,2.57,1.74,4.08,0,3.18-2.61,5.77-5.82,5.77-.89,0-1.77-.21-2.56-.6l-3.5,.92,.94-3.42Z"
								fill="#25d366"
							/>
							<path
								d="M9.69,2.09c-.98-.98-2.29-1.53-3.68-1.53C3.14,.57,.81,2.9,.81,5.77c0,.92,.24,1.81,.69,2.6l-.74,2.7,2.76-.72c.76,.41,1.61,.63,2.49,.63h0c2.87,0,5.25-2.33,5.25-5.2,0-1.39-.59-2.7-1.57-3.68Zm-3.68,8.01c-.78,0-1.54-.21-2.2-.6l-.16-.09-1.64,.43,.44-1.6-.1-.16c-.43-.69-.66-1.48-.66-2.3C1.69,3.39,3.63,1.45,6.01,1.45c1.16,0,2.24,.45,3.06,1.27s1.32,1.9,1.31,3.06c0,2.39-1.99,4.33-4.37,4.33Zm2.37-3.24c-.13-.07-.77-.38-.89-.42-.12-.04-.21-.07-.29,.07-.09,.13-.34,.42-.41,.51-.07,.09-.15,.1-.28,.03-.76-.38-1.27-.68-1.77-1.55-.13-.23,.13-.21,.38-.71,.04-.09,.02-.16-.01-.23s-.29-.71-.4-.97c-.11-.25-.21-.22-.29-.22-.07,0-.16,0-.25,0s-.23,.03-.35,.16c-.12,.13-.45,.45-.45,1.09s.47,1.26,.53,1.35c.07,.09,.92,1.4,2.22,1.96,.83,.36,1.15,.39,1.56,.33,.25-.04,.77-.31,.88-.62s.11-.56,.08-.62c-.03-.06-.12-.09-.25-.15Z"
								fill="#ffffff"
							/>
						</svg>
					</a>
					<a
						alt="Llamar por teléfono"
						href={`tel:+34${dataComercial.telefono}`}
						className="flex justify-center items-center h-8 w-8 md:h-12 md:w-12 bg-indigo-600 rounded-full hover:bg-indigo-700 transition-all"
						target="_blank"
						rel="noreferrer"
					>
						<FiPhoneCall color="#fff" className="text-14 md:text-[23px]" />
					</a>
					<button
						type="button"
						className="flex justify-center items-center h-8 w-8 md:h-12 md:w-12 bg-sky-500 rounded-full hover:bg-sky-600 transition-all"
						onClick={scrollToForm}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							className="w-5 h-5 md:w-7 md:h-7 stroke-white"
						>
							<title>Formulario de contacto</title>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	);
};

export default VehicleBasicInfo;
