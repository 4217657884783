// Dependencies
import { useIntl } from "gatsby-plugin-intl";
import React, { useState } from "react";

export default function MultiselectFilter({
	id,
	onApply,
	state,
	values,
	label,
}) {
	const intl = useIntl();
	const stateArray = state[id] ? state[id].split(",") : [];
	const [expanded, setExpanded] = useState(false);

	function handleExpanded() {
		setExpanded((expanded) => !expanded);
	}

	function handleClick(value) {
		if (!stateArray.includes(value)) {
			return onApply(id, [...stateArray, value].toString());
		} else {
			return onApply(
				id,
				stateArray.filter((item) => item !== value).toString(),
			);
		}
	}

	const finalValues = expanded ? values : values.slice(0, 4);

	return values.length > 1 ? (
		<div className="flex flex-col gap-2 border-b border-gray-200 py-2.5">
			<div className="font-medium text-md flex gap-1 items-center">
				<span>{label}</span>
				<span className="text-sm text-gray-500">{`(${stateArray.length}/${values.length})`}</span>
			</div>
			<ul className="transition-all flex flex-col gap-1">
				{finalValues.map((filter) => {
					return (
						<li key={filter.key} className="w-full">
							<div className="flex items-center gap-1 justify-center">
								<input
									id={`${id}.${filter.key}`}
									className="w-4 h-4 accent-trucksur-600 bg-gray-100 border-gray-300 rounded"
									type="checkbox"
									onChange={() => handleClick(filter.key)}
									checked={stateArray.includes(filter.key)}
								/>
								<label
									htmlFor={`${id}.${filter.key}`}
									className="w-full text-sm m-0"
								>
									{filter.label}
								</label>
							</div>
						</li>
					);
				})}
			</ul>
			{values.length > 4 ? (
				<button
					onClick={handleExpanded}
					type="button"
					className="max-w-max text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-4 focus:ring-blue-300 rounded-full text-sm px-2 py-[2px] text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
				>
					{expanded
						? `- ${intl.formatMessage({ id: "misc_see_less" })}`
						: `+ ${intl.formatMessage({ id: "misc_see_more" })}`}
				</button>
			) : null}
		</div>
	) : null;
}
