// Dependencies
import React, { useState } from "react";
import { format, subMonths } from "date-fns";
import { MultiselectFilter, RangeSliderFilter, TextFilter } from "@components";
import {
	getAdrValues,
	getBrandValues,
	getExtrasValues,
	getGearboxValues,
	getKmsValues,
	getMmaValues,
	getPlateDateValues,
	getSubcategoryValues,
	getTypeValues,
	getUbicationValues,
	getAuxBrakeValues,
} from "@lib/filters";
import { prettyNumber } from "@lib/utils";
import { useCollapse } from "react-collapsed";
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl";

export default function FiltersVehicles({
	vehicles,
	stateFilters,
	cleanFilterById,
	cleanFilters,
	applyFilter,
	isSegment,
}) {
	const intl = useIntl();
	const [expanded, setExpanded] = useState(true);
	const { getCollapseProps, getToggleProps } = useCollapse({
		isExpanded: expanded,
	});

	const typeValues = getTypeValues(vehicles);
	const subcategoryValues = getSubcategoryValues(vehicles);
	const ubicationValues = getUbicationValues(vehicles);
	const extrasValues = getExtrasValues(vehicles);
	const brandValues = getBrandValues(vehicles);
	const gearboxValues = getGearboxValues(vehicles);
	const auxBrakeValues = getAuxBrakeValues(vehicles);
	const adrValues = getAdrValues(vehicles);
	const kmsValuesProps = getKmsValues(vehicles);
	const plateDateValuesProps = getPlateDateValues(vehicles);
	const mmaValuesProps = getMmaValues(vehicles);

	const actualFilters = Object.entries(stateFilters);

	const filtersLabelIntlId = {
		tipo: "vehicle.tipo_de_vehiculo",
		subcategoria: "vehicle.subcategoria",
		marca: "vehicle.marca",
		modelo: "vehicle.modelo",
		fecha_matriculacion: "vehicle.fecha_de_matriculacion",
		matricula: "vehicle.matricula",
		kms: "vehicle.kms_segun_tacografo",
		ubicacion: "vehicle.campa",
		adr: "vehicle.adr",
		caja_cambios: "vehicle.caja_de_cambios",
		mma: "vehicle.pma",
		freno_auxiliar: "vehicle.freno_auxiliar",
		extras: "vehicle.extras",
	};

	function formatterPlatesDate(value) {
		const monthsToSub = value === -1 ? 0 : value * -1 - 1;
		const actualDate = new Date();
		const parseDate = subMonths(actualDate, monthsToSub);
		return format(parseDate, "MM/yyyy");
	}

	return (
		<div className="flex flex-col border border-gray-200 rounded-lg p-2 h-min max-w-full md:min-w-[280px] md:max-w-[280px]">
			<div className="font-medium text-xl my-2 flex items-center gap-2">
				<button
					type="button"
					className="block md:hidden"
					{...getToggleProps({
						onClick: () => setExpanded((expanded) => !expanded),
					})}
				>
					{!expanded ? (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className=""
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<title>{intl.formatMessage({ id: "misc_show_filter" })}</title>
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
							<path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
						</svg>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className=""
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<title>{intl.formatMessage({ id: "misc_hide_filter" })}</title>
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
							<path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
							<path d="M3 3l18 18" />
						</svg>
					)}
				</button>
				<span>
					<FormattedMessage id="misc_filter" />
				</span>
			</div>
			<div className="flex gap-1 flex-wrap">
				{actualFilters.map(([id]) => {
					return (
						<button
							className="flex gap-1 items-center justify-between text-white bg-transparent border-red-600 border focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-xs px-2 py-1 text-center"
							type="button"
							key={id}
							onClick={() => cleanFilterById(id)}
						>
							<div className="text-red-600">
								{intl.formatMessage({ id: filtersLabelIntlId[id] }) || id}
							</div>
							<div className="rounded-full w-5 h-5 bg-red-600 flex justify-center items-center">
								<svg
									className="fill-white w-4 h-4"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									strokeWidth="2"
									stroke="currentColor"
									fill="none"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<title>Delete</title>
									<path stroke="none" d="M0 0h24v24H0z" fill="none" />
									<path d="M18 6l-12 12" />
									<path d="M6 6l12 12" />
								</svg>
							</div>
						</button>
					);
				})}
			</div>
			{actualFilters.length ? (
				<button
					onClick={cleanFilters}
					type="button"
					className="mt-2 text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-2 py-1 text-center mr-2 mb-2"
				>
					<FormattedMessage id="misc_reset_filter" />
				</button>
			) : null}

			<div {...getCollapseProps()} className="flex flex-col">
				{isSegment ? (
					<div className="text-sm bg-orange-100 px-2 py-1 rounded-lg">
						<span>
							Actualmente está dentro de un tipo de vehículo, si desea filtrar
							por varios tipos de vehículo pulse{" "}
						</span>
						<Link className="font-bold" to="/vehiculos">
							aquí
						</Link>
					</div>
				) : (
					<MultiselectFilter
						id="tipo"
						onApply={applyFilter}
						state={stateFilters}
						values={typeValues}
						label={intl.formatMessage({ id: filtersLabelIntlId["tipo"] })}
					/>
				)}

				<MultiselectFilter
					id="subcategoria"
					onApply={applyFilter}
					state={stateFilters}
					values={subcategoryValues}
					label={intl.formatMessage({ id: filtersLabelIntlId["subcategoria"] })}
				/>
				<MultiselectFilter
					id="marca"
					onApply={applyFilter}
					state={stateFilters}
					values={brandValues}
					label={intl.formatMessage({ id: filtersLabelIntlId["marca"] })}
				/>
				<TextFilter
					id="modelo"
					onApply={applyFilter}
					state={stateFilters}
					label={intl.formatMessage({ id: filtersLabelIntlId["modelo"] })}
				/>
				<RangeSliderFilter
					id="fecha_matriculacion"
					onApply={applyFilter}
					state={stateFilters}
					label={intl.formatMessage({
						id: filtersLabelIntlId["fecha_matriculacion"],
					})}
					formatter={formatterPlatesDate}
					data={plateDateValuesProps}
				/>
				<TextFilter
					id="matricula"
					onApply={applyFilter}
					state={stateFilters}
					label={intl.formatMessage({ id: filtersLabelIntlId["matricula"] })}
				/>
				<RangeSliderFilter
					id="kms"
					onApply={applyFilter}
					state={stateFilters}
					label={intl.formatMessage({ id: filtersLabelIntlId["kms"] })}
					formatter={(value) => prettyNumber(Math.ceil(value))}
					data={kmsValuesProps}
				/>
				<MultiselectFilter
					id="adr"
					onApply={applyFilter}
					state={stateFilters}
					values={adrValues}
					label={intl.formatMessage({ id: filtersLabelIntlId["adr"] })}
				/>
				<MultiselectFilter
					id="caja_cambios"
					onApply={applyFilter}
					state={stateFilters}
					values={gearboxValues}
					label={intl.formatMessage({ id: filtersLabelIntlId["caja_cambios"] })}
				/>
				<RangeSliderFilter
					id="mma"
					onApply={applyFilter}
					state={stateFilters}
					label={intl.formatMessage({ id: filtersLabelIntlId["mma"] })}
					formatter={(value) => prettyNumber(Math.ceil(value))}
					data={mmaValuesProps}
				/>
				<MultiselectFilter
					id="freno_auxiliar"
					onApply={applyFilter}
					state={stateFilters}
					values={auxBrakeValues}
					label={intl.formatMessage({
						id: filtersLabelIntlId["freno_auxiliar"],
					})}
				/>
				<MultiselectFilter
					id="extras"
					onApply={applyFilter}
					state={stateFilters}
					values={extrasValues}
					label={intl.formatMessage({ id: filtersLabelIntlId["extras"] })}
				/>
				<MultiselectFilter
					id="ubicacion"
					onApply={applyFilter}
					state={stateFilters}
					values={ubicationValues}
					label={intl.formatMessage({ id: filtersLabelIntlId["ubicacion"] })}
				/>
			</div>
		</div>
	);
}
