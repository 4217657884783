// Dependencies
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@components";

export default function ModalTypeDatasheet({
	visible,
	handleClose,
	data,
	downloading,
	handleDownload,
}) {
	const [type, setType] = useState("pvp");

	return (
		<Modal show={visible} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Descarga de ficha</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					El vehículo que ha seleccionado es financiable, por favor seleccione
					que tipo de precio quiere que aparezca en la ficha de cada vehículo:
				</p>

				<hr className="my-3" />
				<div className="grid grid-cols-1 gap-3">
					<label
						for="pvp-radio"
						className="flex gap-2 font-medium text-gray-900 dark:text-gray-300 mb-0"
					>
						<input
							id="pvp-radio"
							aria-describedby="helper-pvp"
							type="radio"
							checked={type === "pvp"}
							onChange={() => setType("pvp")}
							className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
						/>
						<div>
							<span>Precio PVP</span>
							<p
								id="helper-pvp"
								className="text-xs font-normal text-gray-500 dark:text-gray-300"
							>
								Precio total PVP
							</p>
						</div>
					</label>

					<label
						for="financial-radio"
						className="flex gap-2 font-medium text-md text-gray-900 dark:text-gray-300 mb-0"
					>
						<input
							id="financial-radio"
							aria-describedby="helper-financial"
							type="radio"
							checked={type === "financial"}
							onChange={() => setType("financial")}
							className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
						/>
						<div>
							<span>Precio Financiable</span>
							<p
								id="helper-financial"
								className="text-xs font-normal text-gray-500 dark:text-gray-300"
							>
								Precio con financiación detallada
							</p>
						</div>
					</label>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					className="bg-trucksur-600 hover:bg-trucksur-600"
					onClick={() => handleDownload(data, type)}
					isLoading={downloading}
				>
					Descargar
				</Button>
				<Button
					className="bg-slate-400  hover:bg-slate-400"
					onClick={handleClose}
				>
					Cancelar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
