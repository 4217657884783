const categoriesIcons = {
	cat_tractor_head: require("../images/cat_tractor_head.svg"),
	cat_tractor_head_for_rent: require("../images/cat_tractor_head.svg"),
	cat_rigid: require("../images/cat_rigid.svg"),
	cat_construction: require("../images/cat_construction.svg"),
	cat_refrigerated: require("../images/cat_refrigerated.svg"),
	cat_semitrailer: require("../images/cat_semitrailer.svg"),
	cat_light: require("../images/cat_light.svg"),
	cat_bus: require("../images/cat_bus.svg"),
	cat_cars: require("../images/cat_cars.svg"),
};

export default categoriesIcons;
