// Dependencies
import React from "react";

export default function LogoRentExperience({ className }) {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 47.48 54.78"
		>
			<title>Percentage</title>

			<path d="M0,11.79c0-2.12,.5-4.07,1.5-5.88,1.02-1.82,2.41-3.26,4.17-4.31C7.42,.53,9.32,0,11.36,0,12.98,0,14.49,.32,15.89,.96c1.42,.64,2.63,1.51,3.63,2.6,1.02,1.06,1.82,2.31,2.39,3.74,.57,1.42,.85,2.92,.85,4.49,0,2.09-.5,4.05-1.5,5.88s-2.37,3.29-4.13,4.38c-1.76,1.09-3.68,1.64-5.77,1.64s-4.08-.55-5.84-1.64c-1.74-1.09-3.09-2.55-4.06-4.38C.49,15.81,0,13.85,0,11.79h0ZM8.69,45.2L32.24,3.84c.76-1.3,1.47-2.27,2.14-2.88,.66-.64,1.46-.96,2.38-.96,.78,0,1.51,.19,2.18,.57,.66,.36,1.19,.86,1.56,1.5,.38,.62,.57,1.28,.57,2,0,.88-.19,1.71-.57,2.49-.38,.76-.95,1.76-1.71,2.99L15.25,50.9c-.69,1.26-1.37,2.22-2.07,2.88-.69,.67-1.51,1-2.46,1-1.21,0-2.23-.4-3.06-1.21s-1.25-1.77-1.25-2.88c0-.88,.18-1.69,.53-2.43,.38-.74,.96-1.76,1.74-3.06Zm-.39-33.41c0,1,.27,1.84,.81,2.53,.57,.66,1.32,1,2.24,1s1.7-.33,2.25-1c.54-.69,.81-1.53,.81-2.53s-.27-1.78-.81-2.42c-.55-.67-1.29-1-2.25-1s-1.67,.33-2.24,1c-.54,.64-.81,1.45-.81,2.42h0Zm24.75,31.17c0,.92,.27,1.73,.82,2.42,.57,.67,1.31,1,2.24,1s1.66-.33,2.21-1c.57-.69,.86-1.5,.86-2.42,0-.97-.29-1.79-.86-2.46-.55-.69-1.28-1.03-2.21-1.03s-1.68,.34-2.24,1.03c-.55,.66-.82,1.48-.82,2.46Zm-8.33,0c0-2.14,.5-4.11,1.5-5.91,1.02-1.82,2.4-3.28,4.13-4.34,1.76-1.09,3.68-1.64,5.77-1.64s4,.55,5.73,1.64c1.73,1.09,3.1,2.55,4.1,4.38,1.02,1.81,1.53,3.76,1.53,5.88s-.51,4.04-1.53,5.84c-1,1.81-2.36,3.25-4.1,4.35s-3.64,1.64-5.73,1.64-4.12-.53-5.88-1.6c-1.73-1.09-3.09-2.55-4.06-4.38s-1.46-3.77-1.46-5.84h0Z" />
		</svg>
	);
}
