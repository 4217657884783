// Dependencies
import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import rentItems from "@constants/rent";

export default function RentItems() {
	const intl = useIntl();

	return (
		<div className="grid grid-cols-2 sm:grid-cols-4">
			{rentItems.map((item) => {
				const svgLogo = React.cloneElement(item.logo(), {
					className: "fill-gray-200 h-[100px] w-[100px] m-auto",
				});
				return (
					<div key={item.key}>
						{item.logo ? svgLogo : false}
						<div className="text-center text-gray-200 font-light text-lg">
							{intl.formatMessage({ id: item.text_id })}
						</div>
					</div>
				);
			})}
		</div>
	);
}
