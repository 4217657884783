// Dependencies
import classNames from "classnames";
import React from "react";
import RangeSlider from "react-range-slider-input";
import { useController } from "react-hook-form";
import "react-range-slider-input/dist/style.css";

export default function InputRangeSlider({
	label,
	id,
	name,
	control,
	...props
}) {
	const { field } = useController({
		control,
		name,
	});

	return (
		<div className="mb-1">
			{label ? (
				<label
					className="block text-trucksur-600 text-lg font-semibold mb-3 "
					htmlFor={id}
				>
					{label}
				</label>
			) : (
				false
			)}
			<RangeSlider
				{...props}
				value={[0, field.value]}
				onInput={([_, val]) => field.onChange(val)}
				thumbsDisabled={[true, false]}
				rangeSlideDisabled={true}
				className={classNames("range-slider-single", props.className)}
			/>
		</div>
	);
}
