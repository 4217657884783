// Dependencies
import React from "react";
import { useIntl } from "gatsby-plugin-intl";

export default function MiniButtonWhatsapp({ data }) {
	const intl = useIntl();
	const { comercial: dataComercial, seo_name } = data;

	const whatsappLink =
		dataComercial &&
		`https://api.whatsapp.com/send?phone=34${dataComercial.telefono}&text=Estoy%20interesado%20en%20el%20siguiente%20veh%C3%ADculo%20https://trucksur.com/${intl.locale}/vehiculo/${seo_name}`;

	function handleClick(ev) {
		ev.preventDefault();
		if (typeof window !== "undefined") {
			window.open(whatsappLink, "_blank");
		}
	}

	return (
		<button
			type="button"
			className="absolute flex items-center justify-center z-[45] right-[10px] top-[50px] hover:scale-110 transition-all"
			onClick={handleClick}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				className="relative w-8 h-8 stroke-transparent"
			>
				<title>Contactar por Whatsapp</title>
				<path
					className="fill-white"
					d="M0,24l1.69-6.16C.65,16.03,.1,13.99,.1,11.89,.1,5.33,5.44,0,11.99,0c3.18,0,6.17,1.24,8.41,3.49,2.25,2.25,3.48,5.24,3.48,8.41,0,6.56-5.34,11.89-11.89,11.89-1.99,0-3.95-.5-5.69-1.45C6.3,22.35,0,24,0,24Z"
				/>
				<path
					className="fill-whatsapp"
					d="M6.6,20.19c1.68,1,3.28,1.59,5.39,1.59,5.45,0,9.89-4.43,9.89-9.89,0-5.46-4.42-9.89-9.88-9.89C6.55,2.01,2.11,6.44,2.11,11.89c0,2.22,.65,3.89,1.75,5.63l-1,3.65s3.74-.98,3.74-.98Z"
				/>
				<path
					className="fill-white"
					d="M17.98,14.73c-.07-.12-.27-.2-.57-.35-.3-.15-1.76-.87-2.03-.97-.27-.1-.47-.15-.67,.15-.2,.3-.77,.97-.94,1.16s-.35,.22-.64,.07c-.3-.15-1.26-.46-2.39-1.48-.88-.79-1.48-1.76-1.65-2.06-.17-.3-.02-.46,.13-.61,.13-.13,.3-.35,.45-.52,.15-.17,.2-.3,.3-.49,.1-.2,.05-.37-.02-.52-.07-.15-.67-1.61-.92-2.21-.24-.58-.49-.5-.67-.51h-.57c-.2-.01-.52,.06-.79,.36s-1.04,1.02-1.04,2.48,1.07,2.88,1.21,3.07c.15,.2,2.1,3.2,5.08,4.49,.71,.31,1.26,.49,1.69,.63,.71,.23,1.36,.19,1.87,.12,.57-.08,1.76-.72,2.01-1.41,.25-.69,.25-1.29,.17-1.41Z"
				/>
			</svg>
		</button>
	);
}
