// Dependencies
import React, { useMemo } from "react";
import { useIntl } from "gatsby-plugin-intl";
import { GatsbyImage } from "gatsby-plugin-image";

export default function RentImage({
	rentTextId,
	textId,
	href,
	image,
	buttonColor,
}) {
	const buttonClassname = useMemo(() => {
		const baseClassname =
			"hover:no-underline hover:text-white block mt-3 p-1 transition-colors";
		return `${baseClassname} bg-${buttonColor}-500 hover:bg-${buttonColor}-600`;
	}, [buttonColor]);

	const intl = useIntl();
	return (
		<div>
			<div className="relative">
				<div
					className={`
						absolute text-white top-5 left-5 z-10 w-20 text-center text-sm overflow-hidden 
						xs:w-36 xs:text-base
						md:w-20 md:text-sm
						lg:w-36 lg:text-lg 
						xl:w-44 xl:text-xl xl:left-8 xl:top-14
			  		`}
				>
					<div>{intl.formatMessage({ id: textId })}</div>
					<a className={buttonClassname} href={href}>
						{intl.formatMessage({ id: rentTextId })}
					</a>
				</div>
			</div>
			<GatsbyImage
				className="!w-full !h-full"
				alt="Alquiler de vehículos"
				image={image}
			/>
		</div>
	);
}
