// Dependencies
import React from "react";

export default function LogoRentAttention() {
	return (
		<svg
			id="Capa_1"
			data-name="Capa 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 198.43 198.43"
		>
			<title>Rent Attention</title>
			<g id="Hlv9aZ">
				<g id="Grupo_4" data-name="Grupo 4">
					<path
						id="Trazado_27"
						data-name="Trazado 27"
						d="M151.93,89.94c-.33,2-.56,3.93-1,5.86a39.82,39.82,0,0,1-16.84,24.61l-.93.68,3.84,1.73a3.25,3.25,0,0,1,2.23,4,2.06,2.06,0,0,1-.12.35c-1.54,5.8-3.11,11.58-4.65,17.38a8.67,8.67,0,0,1-6.64,6.87,14.86,14.86,0,0,1-6.89-.12c-20.15-5.66-36.81-16.86-50.76-32.32a106.81,106.81,0,0,1-23.08-41.2,11.62,11.62,0,0,1,0-7.28A9.36,9.36,0,0,1,54,64.22q8.64-2.3,17.3-4.55a3.29,3.29,0,0,1,4.34,1.68,2.59,2.59,0,0,1,.18.51c.53,1.19,1.07,2.37,1.61,3.58,1.36-1.72,2.59-3.45,4-5a39.11,39.11,0,0,1,24.08-13.29c14.19-2.06,26.19,2.44,35.91,12.89a39.38,39.38,0,0,1,10.39,23.68,4.39,4.39,0,0,0,.18.69ZM103,120.49c2.36-2.3,4.67-4.5,6.94-6.73a3.48,3.48,0,0,1,4.7-.89c3.45,1.53,6.9,3,10.32,4.62a1.82,1.82,0,0,0,2,0,63.14,63.14,0,0,0,6.65-4.45c10-8.75,13.86-19.79,11.37-32.81a32.68,32.68,0,0,0-13.45-20.85,32.44,32.44,0,0,0-27.21-5.7A34.1,34.1,0,0,0,81,71.84,1.71,1.71,0,0,0,81,73.55c1.63,3.59,3.21,7.2,4.83,10.79A3.36,3.36,0,0,1,85,88.47L83.8,89.7,78,95.63a118.56,118.56,0,0,0,25,24.86ZM79.46,85.37c-2.8-6.29-5.54-12.46-8.26-18.64-.26-.57-.59-.51-1.06-.38L55.63,70.18a3.57,3.57,0,0,0-2.54,1.9,4.83,4.83,0,0,0-.18,3.62,99.16,99.16,0,0,0,10.94,24.63,108,108,0,0,0,37.64,36.3,98,98,0,0,0,21.7,9,4,4,0,0,0,5.17-2.13,4.35,4.35,0,0,0,.27-1l1-3.57c1-3.74,2-7.48,3-11.25-.23-.12-.39-.23-.58-.31-6-2.66-12-5.31-17.93-8a1,1,0,0,0-1.24.27q-3.33,3.38-6.7,6.69a3.27,3.27,0,0,1-4.58.66,3.11,3.11,0,0,1-.28-.23A117.69,117.69,0,0,1,71.9,97.63c-1.5-2.12-1.38-3.39.48-5.25,2.33-2.33,4.68-4.64,7.08-7Z"
					/>
					<path
						id="Trazado_28"
						data-name="Trazado 28"
						d="M100.85,96.48c1.64,0,3.29-.11,4.92,0a3,3,0,0,1,2.69,3.26,3.16,3.16,0,0,1-.34,1.12,2.81,2.81,0,0,1-2.54,1.67q-6.33,0-12.66,0a2.85,2.85,0,0,1-2.76-2A3,3,0,0,1,91,97.14c2.72-2.73,5.42-5.46,8.16-8.16a10.09,10.09,0,0,0,3.1-7.88,3.12,3.12,0,0,0-3-3.19,3.08,3.08,0,0,0-3.2,2.94h0a3.06,3.06,0,0,1-3,3.17,2.45,2.45,0,0,1-.54,0,3.14,3.14,0,0,1-2.68-3.53l.06-.32a9.25,9.25,0,0,1,15.45-6,9.15,9.15,0,0,1,3,6.86,15.61,15.61,0,0,1-4.84,12.24c-1,1-2,1.88-3,2.82Z"
					/>
					<path
						id="Trazado_29"
						data-name="Trazado 29"
						d="M127,90.25h-8.38c-2.92,0-4-1-4-4,0-3.78,0-7.55,0-11.33a3,3,0,0,1,2.86-3.23,3.65,3.65,0,0,1,.66,0A3.05,3.05,0,0,1,120.82,75c0,2.64,0,5.29,0,7.93V84H127V82.88c0-2.64,0-5.29,0-7.92a3.06,3.06,0,0,1,2.91-3.21H130a3.06,3.06,0,0,1,3.13,3,1.92,1.92,0,0,1,0,.24q0,12.21,0,24.4a3,3,0,0,1-2.86,3.21h-.17a3.07,3.07,0,0,1-3.14-3v-.24C127,96.37,127,93.39,127,90.25Z"
					/>
				</g>
			</g>
		</svg>
	);
}
