// Dependencies
import React from "react";
import { VehicleSegmentTitle } from "@components";

export default function VehiclesHeader({ qty, idTitleSegment, ...props }) {
	return (
		<div className={props.className}>
			<VehicleSegmentTitle qty={qty} idTitle={idTitleSegment} />
		</div>
	);
}
