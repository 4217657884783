// Dependencies
import React, { useEffect, useState } from "react";

export default function TextFilter({ id, state, onApply, label }) {
	const [value, setValue] = useState("");

	useEffect(() => {
		setValue(state[id] || "");
	}, [state, id]);

	function handleSubmit(ev) {
		ev.preventDefault();
		return onApply(id, value);
	}

	function handleChange(ev) {
		setValue(ev.target.value);
	}

	return (
		<div className="mt-2 flex flex-col gap-1 border-b border-gray-200">
			<div className="m-0 text-md font-medium">{label}</div>
			<form className="flex mb-2 gap-1" onSubmit={handleSubmit}>
				<input
					className="flex-1 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1"
					name="input"
					type="text"
					value={value}
					onChange={handleChange}
					placeholder={`Introduce ${label.toLowerCase()}...`}
				/>
				<button
					className="max-w-max text-white bg-trucksur-600 hover:bg-trucksur-700 focus:outline-none focus:ring-4 focus:ring-blue-300 rounded-lg px-2 text-sm text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
					type="submit"
				>
					OK
				</button>
			</form>
		</div>
	);
}
