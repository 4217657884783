// Dependencies
import React from "react";

export default function LogoRentAvailability() {
	return (
		<svg
			id="Capa_1"
			data-name="Capa 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 198.43 198.43"
		>
			<title>Rent Availability</title>
			<path
				id="Trazado_37"
				data-name="Trazado 37"
				d="M157.64,98.73c-2.41-1.06-4.84-2-7.21-3.17a3.5,3.5,0,0,1-1.52-1.43c-2.4-4.52-4.65-9.13-7.11-13.63a10.44,10.44,0,0,0-8.94-5.69c-4.06-.22-8.13-.11-12.19-.16-.47,0-.94,0-1.48-.06,0-1.13,0-2.12,0-3.1A11.42,11.42,0,0,0,110.7,60a6.65,6.65,0,0,0-11-7.21L93,59.48H48a10.88,10.88,0,0,0-9.66,4.91,29.63,29.63,0,0,0-2.22,5.52v56.92a2.1,2.1,0,0,1,.22.42,7.14,7.14,0,0,0,7,5.43l1.3.06a14.37,14.37,0,0,0,3.86,10.39,14.6,14.6,0,0,0,19.63,1.68,14.64,14.64,0,0,0,5.71-12.09h48.34c1.39,9.25,6,14.49,13.23,15a14.39,14.39,0,0,0,9.7-2.48,14.66,14.66,0,0,0,6.41-12.68c1.17,0,2.19,0,3.21,0a7.13,7.13,0,0,0,7.49-6.76,5.3,5.3,0,0,0,0-.89c0-6.36,0-12.72,0-19.09A7.16,7.16,0,0,0,157.64,98.73ZM59.33,142.27a8.95,8.95,0,1,1,8.9-9,9,9,0,0,1-8.9,9Zm54.17-15.18h-1.32q-19.22,0-38.41,0a1.89,1.89,0,0,1-1.9-1.13,13.82,13.82,0,0,0-12.56-7.26A13.93,13.93,0,0,0,46.71,126c-.25.42-.65,1.07-1,1.07a16.83,16.83,0,0,1-3.46-.39c-.22,0-.45-.69-.45-1,0-4.79,0-9.59,0-14.46H113.5Zm0-21.76H41.78V71.51a5.9,5.9,0,0,1,4.32-6.13,9.51,9.51,0,0,1,2.32-.22H87.3q-8.6,8.58-17.18,17.18a5,5,0,0,0-.46.67L69,82.39,58.29,71.68c-.82-.82-1.63-1.68-2.5-2.45a6.66,6.66,0,0,0-9.4.6c-.12.14-.24.29-.35.44a8.65,8.65,0,0,0-1.29,3.21v1.43a9.15,9.15,0,0,0,3.13,5.21C53.38,85.56,58.82,91,64.29,96.51c.28.27.55.55.84.81a6.33,6.33,0,0,0,3.75,1.61,6.85,6.85,0,0,0,5.67-2.2q15.77-15.81,31.57-31.58h.68c4.44,0,6.74,2.31,6.74,6.76q0,16.07,0,32.14Zm5.74-25h2c3.77,0,7.54,0,11.31.16a4.85,4.85,0,0,1,4.21,2.6c2,3.65,3.92,7.36,6,11.28H119.29Zm17.66,62a8.95,8.95,0,1,1,9-9,9,9,0,0,1-9,9Zm19.7-17.3c0,1.71-.41,2.11-2.1,2.12h-.13c-2.6.33-4.95.13-6-2.84a14.49,14.49,0,0,0-24,1.77,1.79,1.79,0,0,1-1.92,1.09c-1.05-.08-2.12,0-3.25,0V100h1.38c8.34,0,16.66,0,25,0a5.7,5.7,0,0,1,2.12.44c2.46,1,4.87,2.16,7.33,3.19a2.22,2.22,0,0,1,1.58,2.32c0,6.33,0,12.65,0,19Z"
			/>
		</svg>
	);
}
