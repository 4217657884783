// Dependencies
import React from "react";
import { Carousel } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";

export default function HomeCarousel({ data, className }) {
	return (
		<Carousel className={className}>
			{data.map((item, index) => {
				return (
					<Carousel.Item key={index.toString()}>
						<div className="h-[450px]">
							<a
								href={item.href}
								alt={item.title}
								target="_blank"
								rel="noreferrer"
							>
								{item.title && (
									<div className="w-full h-full flex items-center justify-center absolute top-0 left-0 z-[1]">
										<div className="my-0 mx-4 bg-[rgba(39,43,95,0.8)] max-w-[400px] p-[1rem] text-white text-center text-[2.5rem] font-bold lg:text-[3rem] lg:max-w-[400px] xl:text-[4rem] xl:max-w-[600px]">
											{item.title}
										</div>
									</div>
								)}
								<GatsbyImage
									className="max-h-full min-h-full max-w-full min-w-full object-cover"
									image={item.image.localFile.childImageSharp.gatsbyImageData}
									alt={item.alt || ""}
								/>
							</a>
						</div>
					</Carousel.Item>
				);
			})}
		</Carousel>
	);
}
