// Dependencies
import { capitalize } from "lodash";
import { formatDate, prettyNumber } from "@lib/utils";
import fieldsVehicleType from "@constants/fieldsVehicleType";

const commonTopFields = [
	{ key: "matricula" },
	{ key: "tipo_de_vehiculo" },
	{ key: "fecha_de_matriculacion", formatter: (value) => formatDate(value) },
	{ key: "marca", formatter: (value) => capitalize(value) },
	{ key: "modelo", formatter: (value) => capitalize(value) },
	{ key: "configuracion" },
];

const commonBottomFields = [
	{ key: "estado", formatter: (value) => capitalize(value) },
	{
		key: "campa",
		formatter: (value) => capitalize(value),
	},
	{ key: "texto_descriptivo", formatter: (value) => capitalize(value) },
	{ key: "observaciones", formatter: (value) => capitalize(value) },
];

const displayedExtras = [
	"Aire acondicionado",
	"Enfriador auxiliar de cabina",
	"Calefacción",
	"Calefacción auxiliar",
	"Equipo hidráulico",
	"Plataforma elevadora",
	"Toma de fuerza",
];

function isEmptyValue(value) {
	return !value;
}

function renderCommonValue(object, value) {
	return !value ? "-" : object.formatter ? object.formatter(value) : value;
}

const renderSpecificValue = (key, value) => {
	return key === "nivel_ecologico"
		? value.toUpperCase()
		: typeof value === "string"
		? capitalize(value)
		: typeof value === "number"
		? prettyNumber(value, 0, false)
		: key === "nivel_ecologico"
		? value.toUpperCase()
		: value;
};

export function buildTechnicalData(data, intl) {
	let finalData = [];
	// Comunes parte superior
	commonTopFields.forEach((item) => {
		finalData = [
			...finalData,
			{
				type: "field",
				label: intl.formatMessage({ id: `vehicle.${item.key}` }),
				value: renderCommonValue(item, data[item.key]),
			},
		];
	});
	finalData = [...finalData, { type: "divider" }];
	// Specified fields
	fieldsVehicleType[data["tipo_de_vehiculo"]].forEach((item) => {
		if (!isEmptyValue(data[item])) {
			finalData = [
				...finalData,
				{
					type: "field",
					label: intl.formatMessage({ id: `vehicle.${item}` }),
					value: renderSpecificValue(item, data[item]),
				},
			];
		}
	});
	finalData = [...finalData, { type: "divider" }];
	// Displayed extras
	displayedExtras.forEach((item) => {
		finalData = [
			...finalData,
			{
				type: "field",
				label: item,
				value: data.extras.includes(item) ? "Si" : "No",
			},
		];
	});
	// Others extras
	const otherExtras = data.extras.filter(
		(item) => !displayedExtras.includes(item),
	);
	if (otherExtras.length) {
		finalData = [
			...finalData,
			{
				type: "field",
				label: intl.formatMessage({ id: "vehicle.extras" }),
				value: otherExtras.join(", "),
			},
		];
	}
	finalData = [...finalData, { type: "divider" }];
	// Commons fields bottom
	commonBottomFields.forEach((item) => {
		const ubicationName = data?.ubicacion?.nombre_ubicacion;
		finalData = [
			...finalData,
			{
				type: "field",
				label: intl.formatMessage({ id: `vehicle.${item.key}` }),
				value: renderCommonValue(
					item,
					item.key === "campa"
						? ubicationName || data[item.key]
						: data[item.key],
				),
			},
		];
	});

	return finalData;
}
