// Dependencies
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { GATSBY_GOOGLE_CAPTCHA_KEY } from "@lib/env";

export default React.forwardRef((props, ref) => {
	return (
		<ReCAPTCHA
			sitekey={GATSBY_GOOGLE_CAPTCHA_KEY}
			size="invisible"
			ref={ref}
			{...props}
		/>
	);
});
