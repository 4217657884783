// Dependencies
import { useState, useRef } from "react";
import * as yup from "yup";
import { post } from "@api/apiInstance";
import { useIntl } from "gatsby-plugin-intl";
import toast from "react-hot-toast";

export default function useFooter() {
	const [fetching, setFetching] = useState(false);
	const intl = useIntl();
	const recaptchaRef = useRef();

	const INITIAL_VALUES = { email: "" };

	const schema = yup.object({
		email: yup
			.string()
			.email(intl.formatMessage({ id: "validation_email" }))
			.required(intl.formatMessage({ id: "validation_required" })),
	});

	const onSubmit = async (values, { resetForm }) => {
		setFetching(true);
		try {
			const recaptchaToken = await recaptchaRef.current.executeAsync();
			await post("/newsletter_users", { ...values, recaptchaToken });

			setFetching(false);
			toast("✅ Email introducido correctamente", {
				className: "text-sm",
			});
			resetForm();
		} catch (err) {
			console.log("se produjo un error");
			toast("❌ Se produjo un error, vuelva a intentarlo", {
				className: "text-sm",
			});
			setFetching(false);
		}
	};

	return {
		schema,
		onSubmit,
		initialValues: INITIAL_VALUES,
		fetching,
		recaptchaRef,
	};
}
