// Dependencies
import axios from "axios";
import { downloadFile } from "../lib/utils";
import { GATSBY_API_ROOT } from "../lib/env";

const myFetch = (endpoint, config) => {
	const endpointUrl = GATSBY_API_ROOT.concat(endpoint);
	return axios(endpointUrl, config);
};

// GET
export const get = async (url) => {
	const response = await myFetch(url, { method: "GET" });
	return [response.data, response];
};

// POST
export const post = async (url, data) => {
	const response = await myFetch(url, {
		method: "POST",
		data,
	});
	return [response.data, response];
};

export const getPdf = async (url, params, filename = "report.pdf") => {
	const response = await myFetch(url, {
		responseType: "blob",
		params,
	});
	downloadFile(response.data, "application/pdf", filename);
};
