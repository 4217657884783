// Dependencies
import React, { useRef } from "react";
import classNames from "classnames";
import { useCallback, useEffect } from "react";
import { useController } from "react-hook-form";
import { useIntl } from "gatsby-plugin-intl";
import { ErrorMessage } from "@hookform/error-message";

function getFormatValue(value, locale) {
	return value && locale
		? new Intl.ListFormat(locale, {
				style: "long",
				type: "conjunction",
		  }).format(value)
		: "";
}

export default function InputMultiselectForm({
	control,
	defaultSelected,
	name,
	label,
	id,
	options,
	isRequired,
	className,
	errors,
}) {
	const { locale } = useIntl();
	const dropdownRef = useRef();
	const inputRef = useRef();

	const { field } = useController({
		control,
		name,
		defaultValue: {
			isOpen: false,
			value: defaultSelected,
		},
	});

	const selectValue = field?.value?.value;
	const isOpen = field?.value?.isOpen;

	useEffect(() => {
		const inputValue = getFormatValue(selectValue, locale);
		inputRef.current.value = inputValue;
	}, [selectValue, locale]);

	const handleClose = useCallback(() => {
		field.onChange((value) => ({ ...value, isOpen: false }));
	}, [field]);

	function handleOpen() {
		field.onChange((value) => ({ ...value, isOpen: true }));
	}

	useEffect(() => {
		function handler(ev) {
			const isClickOutside = !dropdownRef?.current?.contains(ev.target);
			if (isOpen && isClickOutside) handleClose();
			return;
		}
		document.addEventListener("mousedown", handler);
		return () => {
			document.removeEventListener("mousedown", handler);
		};
	}, [isOpen, handleClose]);

	function handleChange(ev) {
		const currentValue = ev?.currentTarget?.value;
		const currentChecked = ev?.currentTarget?.checked;
		if (ev.currentTarget === undefined) return;

		const newValue = currentChecked
			? [...selectValue, currentValue]
			: selectValue.filter((item) => item !== currentValue);

		inputRef.current.value = getFormatValue(newValue, locale);

		field.onChange((value) => ({
			...value,
			value: newValue,
		}));
	}

	const textLabel = isRequired ? `* ${label}` : label;
	const widthDropdown = dropdownRef?.current?.getBoundingClientRect()?.width;

	return (
		<div className={className}>
			{label ? (
				<label
					className="block text-gray-700 text-sm font-bold mb-2"
					htmlFor={id}
				>
					{textLabel}
				</label>
			) : (
				false
			)}
			<div ref={dropdownRef} className="relative">
				<input
					ref={inputRef}
					onClick={() => (isOpen ? handleClose() : handleOpen())}
					data-toggle="dropdown"
					aria-haspopup="true"
					className="truncate cursor-pointer caret-transparent text-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-400"
					spellCheck="false"
					placeholder="Pulse para seleccionar una o varias opciones"
					readOnly
				/>
				<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
					<svg
						className="fill-current h-4 w-4"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
					>
						<title>chevron</title>
						<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
					</svg>
				</div>
				<div
					className={classNames(
						"absolute mt-2 z-[10]",
						{ block: isOpen },
						{ hidden: !isOpen },
					)}
					style={{ width: `${widthDropdown}px` }}
				>
					<ul className="w-full text-sm text-gray-900 bg-white border border-gray-200 rounded-lg">
						{options.map((option, index) => {
							return (
								<li
									key={index.toString()}
									className="w-full border-b border-gray-200 rounded-t-lg"
								>
									<div className="flex items-center pl-3">
										<input
											id={`multiselect-${name}-${index}`}
											value={option.key}
											type="checkbox"
											className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
											onChange={handleChange}
											checked={field?.value?.value?.indexOf(option.key) > -1}
										/>
										<label
											htmlFor={`multiselect-${name}-${index}`}
											className="my-0 w-full py-2 px-2 text-sm text-gray-900"
										>
											{option.label}
										</label>
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
			{errors && (
				<div className="text-red-600 text-sm mt-1.5 font-light">
					<ErrorMessage name={`${name}.value`} errors={errors} />
				</div>
			)}
		</div>
	);
}
