// Dependencies
import React, { useState, useRef, useMemo } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useIntl, Link } from "gatsby-plugin-intl";
import { post } from "@api/apiInstance";
import { Recaptcha, Button } from "@components";
import { getCountriesByLanguage } from "@lib/utils";
import { graphql, useStaticQuery } from "gatsby";
import toast from "react-hot-toast";

export default function BuyForm() {
	const { allCountry, allProvinceEs } = useStaticQuery(
		graphql`
      query {
        allCountry {
          edges {
            node {
              id
              name
              alpha2Code
              translations {
                es
                en
                ca
                fr
                pt
              }
            }
          }
        }
        allProvinceEs {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
	);

	const countriesData = allCountry.edges.map(({ node }) => ({ ...node }));
	const provincesData = allProvinceEs.edges.map(({ node }) => ({ ...node }));

	const recaptchaRef = useRef();
	const [fetching, setFetching] = useState(false);
	const intl = useIntl();

	const countries = useMemo(() => {
		const countries = [
			...getCountriesByLanguage(intl.locale, countriesData).filter(
				(item) => item.key === "ES",
			),
			...getCountriesByLanguage(intl.locale, countriesData).filter(
				(item) => item.key !== "ES",
			),
		];
		return countries;
	}, [intl, countriesData]);

	const schema = yup.object({
		full_name: yup
			.string()
			.required(intl.formatMessage({ id: "validation_required" })),
		email: yup
			.string()
			.email(intl.formatMessage({ id: "validation_email" }))
			.required(intl.formatMessage({ id: "validation_required" })),
		country: yup
			.string()
			.required(intl.formatMessage({ id: "validation_required" })),
		province: yup
			.string()
			.required(intl.formatMessage({ id: "validation_required" })),
		phone: yup
			.string()
			.required(intl.formatMessage({ id: "validation_required" })),
		accept_privacy: yup
			.bool()
			.required(intl.formatMessage({ id: "validation_conditions" }))
			.oneOf([true], intl.formatMessage({ id: "validation_conditions" })),
	});

	const acceptPrivacityLabel = intl.formatMessage(
		{ id: "misc_accept_privacy" },
		{
			misc_privacy: (
				<Link to="/politica-privacidad/">
					{intl.formatMessage({ id: "misc_privacy" })}
				</Link>
			),
			misc_legal: (
				<Link to="/politica-legal/">
					{intl.formatMessage({ id: "misc_legal" })}
				</Link>
			),
		},
	);

	const onSubmit = async (values, { resetForm }) => {
		setFetching(true);
		try {
			const recaptchaToken = await recaptchaRef.current.executeAsync();
			await post("/buy_requests", { ...values, recaptchaToken });
			toast("✅ Solicitud enviada correctamamente", {
				className: "text-sm",
			});
			resetForm();
		} catch (err) {
			console.error(err);
			toast("❌ Se produjo un error, vuelva a intentarlo", {
				className: "text-sm",
			});
		} finally {
			setFetching(false);
		}
	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={{
				full_name: "",
				email: "",
				country: "",
				phone: "",
				brand: "",
				model: "",
				date_matriculation: "",
				accept_phone_call: false,
				accept_privacy: false,
				accept_newsletter: true,
			}}
		>
			{({
				handleSubmit,
				handleChange,
				values,
				touched,
				errors,
				setFieldValue,
			}) => {
				return (
					<Form noValidate onSubmit={handleSubmit}>
						<Row>
							<Col md={12}>
								<Form.Group>
									<Form.Label>
										* {intl.formatMessage({ id: "misc_full_name" })}
									</Form.Label>
									<Form.Control
										type="text"
										name="full_name"
										value={values.full_name}
										onChange={handleChange}
										isInvalid={touched.full_name && errors.full_name}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.full_name}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label>
										* {intl.formatMessage({ id: "misc_country" })}
									</Form.Label>
									<Form.Control
										as="select"
										name="country"
										value={values.country}
										isInvalid={touched.country && errors.country}
										onChange={(ev) => {
											handleChange(ev);
											setFieldValue("province", "");
										}}
									>
										{[
											<option disabled key={0} value="">
												Seleccione una opción
											</option>,
											...countries.map(({ key, label }) => {
												return (
													<option key={key} value={key}>
														{label}
													</option>
												);
											}),
										]}
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										{errors.country}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label>* Provincia</Form.Label>
									<Form.Control
										as="select"
										name="province"
										value={values.province}
										isInvalid={
											values.country === "ES" &&
											touched.province &&
											errors.province
										}
										onChange={(ev) => {
											handleChange(ev);
										}}
									>
										{values.country === "ES"
											? [
													<option disabled key={0} value="">
														Seleccione una opción
													</option>,
													...provincesData.map((province) => {
														return (
															<option key={province.id} value={province.id}>
																{province.name}
															</option>
														);
													}),
											  ]
											: [
													<option disabled key={0} value="">
														Seleccione una opción
													</option>,
													<option key={1} value="OTRAS">
														Otras
													</option>,
											  ]}
									</Form.Control>
									<Form.Control.Feedback
										type="invalid"
										style={{ display: "block" }}
									>
										{errors.province}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>

							<Col md={6}>
								<Form.Group>
									<Form.Label>
										* {intl.formatMessage({ id: "misc_email" })}
									</Form.Label>
									<Form.Control
										type="email"
										name="email"
										value={values.email}
										onChange={handleChange}
										isInvalid={touched.email && errors.email}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.email}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label>
										* {intl.formatMessage({ id: "misc_phone" })}
									</Form.Label>
									<Form.Control
										type="text"
										name="phone"
										value={values.phone}
										onChange={handleChange}
										isInvalid={touched.phone && errors.phone}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.phone}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>
										{intl.formatMessage({ id: "misc_brand" })}
									</Form.Label>
									<Form.Control
										type="text"
										name="brand"
										value={values.brand}
										onChange={handleChange}
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>
										{intl.formatMessage({ id: "misc_model" })}
									</Form.Label>
									<Form.Control
										type="text"
										name="model"
										value={values.model}
										onChange={handleChange}
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>
										{intl.formatMessage({ id: "misc_date_matriculation" })}
									</Form.Label>
									<Form.Control
										type="date"
										name="date_matriculation"
										value={values.date_matriculation}
										onChange={handleChange}
									/>
								</Form.Group>
							</Col>
							<Col xs={12}>
								<Form.Group>
									<Form.Check
										checked={values.accept_phone_call}
										name="accept_phone_call"
										onChange={(ev) => {
											setFieldValue("accept_phone_call", ev.target.checked);
										}}
										label={intl.formatMessage({ id: "misc_accept_phone_call" })}
									/>
								</Form.Group>
							</Col>
							<Col xs={12}>
								<Form.Group>
									<Form.Check
										checked={values.accept_privacy}
										name="accept_privacy"
										onChange={(ev) => {
											setFieldValue("accept_privacy", ev.target.checked);
										}}
										label={acceptPrivacityLabel}
										isInvalid={touched.accept_privacy && errors.accept_privacy}
										feedback={errors.accept_privacy}
									/>
								</Form.Group>
							</Col>
							<Col xs={12}>
								<Form.Group>
									<Form.Check
										checked={values.accept_newsletter}
										name="accept_newsletter"
										onChange={(ev) => {
											setFieldValue("accept_newsletter", ev.target.checked);
										}}
										label={intl.formatMessage({ id: "misc_accept_newsletter" })}
									/>
								</Form.Group>
							</Col>
							<Col xs={12}>
								<Recaptcha ref={recaptchaRef} />

								<Button
									type="submit"
									color="sky"
									className="mt-2"
									isLoading={fetching}
								>
									{intl.formatMessage({ id: "misc_send" })}
								</Button>
							</Col>
						</Row>
					</Form>
				);
			}}
		</Formik>
	);
}
