const info = [
	{
		key: "info_campa",
		icon: require("../images/info_campa.svg"),
	},
	{
		key: "info_money",
		icon: require("../images/info_money.svg"),
	},
	{
		key: "info_people",
		icon: require("../images/info_people.svg"),
	},
	{
		key: "info_exp",
		icon: require("../images/info_exp.svg"),
	},
	{
		key: "info_vehicles",
		icon: require("../images/info_vehicles.svg"),
	},
	{
		key: "info_we_build",
		icon: require("../images/info_we_build.svg"),
	},
	{
		key: "info_sale_worldwide",
		icon: require("../images/info_sale_worldwide.svg"),
	},
];

export default info;
