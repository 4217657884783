const companies = [
	{
		key: "cica",
		logo: require("../images/com_cica.svg"),
		name: "CICA",
		text_id: "about_com_cica",
		url: "https://www.grupocica.com/",
	},
	{
		key: "covei",
		logo: require("../images/com_covei.svg"),
		name: "COVEI",
		text_id: "about_com_covei",
		url: "https://www.covei.com/",
	},
	{
		key: "covey",
		logo: require("../images/com_covey.svg"),
		name: "COVEY",
		text_id: "about_com_covey",
		url: "https://covey.es/",
	},
	{
		key: "city",
		logo: require("../images/com_city.svg"),
		name: "CARROCERIAS CITY",
		text_id: "about_com_city",
		url: "https://www.carroceriascity.com/",
	},
	{
		key: "r1gama",
		logo: require("../images/com_r1gama.svg"),
		name: "R1 GAMA",
		text_id: "about_com_r1gama",
		url: "https://www.r1gama.com/",
	},
	{
		key: "avisa",
		logo: require("../images/com_avisa.svg"),
		name: "Grupo Avisa",
		text_id: "about_com_avisa",
		url: "https://grupoavisa.com/",
	},
];

export default companies;
