export function calcAmount({ entry, months, monthly, interest }) {
	const interestCalc = interest / 100 / 12;
	return (
		(monthly * (1 - Math.pow(1 + interestCalc, months * -1))) / interestCalc +
		entry
	);
}

export function calcMonthly({
	amount,
	entry,
	months,
	residualValue = 0,
	interest,
}) {
	const interestCalc = interest / 100 / 12;
	return (
		(((amount - entry) /
			((1 - Math.pow(1 + interestCalc, months * -1)) / interestCalc)) *
			months -
			residualValue) /
		months
	);
}

export function millarApproximate(number) {
	const result = Math.round(number / 1000) * 1000;
	return result;
}

export function toMiddleValue(possibleValues) {
	const length = possibleValues.length;
	return length > 2 ? Math.round(length / 2) - 1 : 0;
}
