// Dependencies
import { ErrorMessage } from "@hookform/error-message";
import React from "react";

const InputCheckbox = React.forwardRef(
	({ className, label, errors, ...props }, ref) => {
		const { id, name } = props;
		return (
			<div className={className}>
				<div className="flex">
					<div className="flex items-center h-5">
						<input
							type="checkbox"
							ref={ref}
							id={id}
							aria-describedby="accept_privacy"
							className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
							{...props}
						/>
					</div>
					<div className="ml-2 text-sm">
						<label htmlFor={id} className="text-gray-900 [&>a]:text-sky-700">
							{label}
						</label>
					</div>
				</div>
				{errors && (
					<div className="text-red-600 text-sm font-light">
						<ErrorMessage name={name} errors={errors} />
					</div>
				)}
			</div>
		);
	},
);

export default InputCheckbox;
