// Dependencies
import classNames from "classnames";
import React from "react";

export default function Pagination({
	canPreviousPage,
	canNextPage,
	goPrevPage,
	goNextPage,
	gotoPage,
	currentPage,
	pageCount,
}) {
	return (
		<div className="inline-flex rounded-md shadow-sm" role="group">
			<button
				onClick={() => canPreviousPage && gotoPage(1)}
				type="button"
				className="bg-gray-100 hover:text-gray-800 font-normal p-2.5 text-gray-800  border-gray-200 hover:bg-gray-200 border rounded-l-lg"
			>
				{"<<"}
			</button>
			<button
				onClick={() => canPreviousPage && goPrevPage()}
				type="button"
				className="bg-gray-100 hover:text-gray-800 font-normal p-2.5 text-gray-800  border-gray-200 hover:bg-gray-200 border-t border-b border-r"
			>
				{"<"}
			</button>
			{Array.from({ length: pageCount }, (_, i) => {
				const isActualPage = i + 1 === currentPage;
				return (
					<button
						key={i.toString()}
						onClick={() => gotoPage(i + 1)}
						type="button"
						className={classNames(
							"hover:text-gray-800 font-normal p-2.5 text-gray-800  border-gray-200 hover:bg-gray-200 border-t border-b border-r",
							{
								"bg-truckBlue text-white hover:bg-truckBlue hover:text-white":
									isActualPage,
							},
						)}
					>
						{i + 1}
					</button>
				);
			})}
			<button
				onClick={() => canNextPage && goNextPage()}
				type="button"
				className="bg-gray-100 hover:text-gray-800 font-normal p-2.5 text-gray-800  border-gray-200 hover:bg-gray-200 border-t border-b"
			>
				{">"}
			</button>
			<button
				onClick={() => canNextPage && gotoPage(pageCount)}
				type="button"
				className="bg-gray-100 hover:text-gray-800 font-normal p-2.5 text-gray-800  border-gray-200 hover:bg-gray-200 border rounded-r-md"
			>
				{">>"}
			</button>
		</div>
	);
}
