// Dependencies
import { useWindowSize } from "@uidotdev/usehooks";

export default function useMediaQuery() {
	const { width: windowWidth } = useWindowSize();

	if (windowWidth) {
		return windowWidth >= 1536
			? { mq: "2xl", itemsPerRow: 4, rows: 3 }
			: windowWidth >= 1280
			? { mq: "xl", itemsPerRow: 4, rows: 3 }
			: windowWidth >= 1024
			? { mq: "lg", itemsPerRow: 3, rows: 3 }
			: windowWidth >= 768
			? { mq: "md", itemsPerRow: 2, rows: 3 }
			: windowWidth >= 640
			? { mq: "sm", itemsPerRow: 2, rows: 4 }
			: { mq: "xs", itemsPerRow: 1, rows: 6 };
	}
	return null;
}
