// Dependencies
import { formatMoney } from "accounting-js";
import { format as formatDateFns, differenceInMonths } from "date-fns";
import { deburr } from "lodash";

export const toSimpleString = (string) => {
	if (string && typeof string === "string") {
		return deburr(string.toString().toLowerCase().trim());
	} else {
		return "";
	}
};

export const prettyNumber = (number, decimals, symbol) => {
	const options = {
		symbol: "",
		decimal: ",",
		thousand: ".",
		precision: decimals,
	};

	if (!isNaN(number)) {
		return `${formatMoney(number, options)}${symbol ? ` ${symbol}` : ""}`;
	} else {
		throw new Error("Solo se permiten números");
	}
};

export const formatDate = (date) => {
	return formatDateFns(new Date(date), "dd/MM/yyyy");
};

// Download file of browser
export const downloadFile = (data, type, filename) => {
	const blob = new Blob([data], { type });
	const fileURL = window.URL.createObjectURL(blob);
	const tempLink = document.createElement("a");
	tempLink.href = fileURL;
	tempLink.setAttribute("download", filename);
	return tempLink.click();
};

export const prettyPhoneNumber = (phoneNumber) => {
	const numberArray = Array.from(phoneNumber).reverse();
	return numberArray
		.reduce((acc, cur, index, self) => {
			const isSeparation = (index + 1) % 3 === 0 && self.length !== index + 1;
			return isSeparation ? [...acc, ...[cur, " "]] : [...acc, cur];
		}, [])
		.reverse()
		.join("");
};

export const getArrayRangeEntrada = (amount) => {
	const halfAmount = Math.floor(amount / 2 / 100) * 100;
	const sections = halfAmount / 100;
	return sections ? Array.from({ length: sections }, (_, i) => i * 100) : [0];
};

export const getArrayRangeMonths = (defaultMonths, maxMonths, platesDate) => {
	const vehicleMonths = differenceInMonths(new Date(), new Date(platesDate));
	return defaultMonths.filter((value) => vehicleMonths + value < maxMonths);
};

export function isSoldLabel(status) {
	const matchItems = ["Vendido", "Reservado"];
	return status && matchItems.includes(status);
}

export function getCountriesByLanguage(language, countries) {
	return countries
		.map((country) => {
			const label =
				(language && country.translations && country.translations[language]) ||
				country.name;

			return {
				key: country["alpha2Code"],
				label: label,
			};
		})
		.sort((a, b) => a.label.localeCompare(b.label));
}

// Obtenemos los favoritos acordes a los vehículos
export function getDisplayedFavorites(favorites, vehicles) {
	const uniqueFavorites = [...new Set(favorites)];
	return vehicles.filter((vehicle) => uniqueFavorites.includes(vehicle.id));
}
