export const TYPE_REQUEST_OPTIONS = [
	{
		labelId: "misc_info_request.type_detail_info",
		value: "REQUEST_DETAIL_INFO",
	},
	{
		labelId: "misc_info_request.type_presencial",
		value: "FACE_TO_FACE_VISIT",
	},
	{
		labelId: "misc_info_request.type_videocall",
		value: "REQUEST_VIDEO_CALL",
	},
];
