// Dependencies
import React from "react";

export default function SegmentTitle({ children, ...props }) {
	return (
		<h1 className="text-3xl font-normal text-truckBlue" {...props}>
			{children}
		</h1>
	);
}
