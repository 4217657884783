// Dependencies
import React from "react";
import Slider from "react-slick";
import { VehiclesListItem, VehiclesList } from "@components";

const slickSettings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 2,
	slidesToScroll: 1,
	arrows: false,
	useTransform: false,
	responsive: [
		{
			breakpoint: 640,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};

export default function VehiclesCardCarousel({
	data,
	proxEntImg,
	interest,
	location,
}) {
	return (
		<div>
			<div className="mt-4 block lg:hidden">
				<Slider {...slickSettings}>
					{data.map((item, index) => {
						return (
							<div className="px-3" key={index.toString()}>
								<VehiclesListItem
									data={item}
									proxEntImg={proxEntImg}
									interest={interest}
								/>
							</div>
						);
					})}
				</Slider>
			</div>
			<div className="hidden lg:block mt-4">
				<VehiclesList
					data={{
						vehicles: data,
						paginatedVehicles: data,
						proxEntradaImg: proxEntImg,
						interest: interest,
					}}
					location={location}
					renderBanners={false}
					renderPriceDetails={false}
				/>
			</div>
		</div>
	);
}
